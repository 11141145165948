import React, { useState, useEffect } from 'react';

const Modal = ({ isOpen, onClose }) => {

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setShowModal(isOpen), 300); // Match the transition duration
        return () => clearTimeout(timeout);
    }, [isOpen]);

    return (
        <div className='modal-overlay relative w-full h-full flex-1'>
            <div className="fixed bg-black/20 inset-0"></div>
            <div className={`modal-content absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${showModal ? ' scale-100 opacity-100' : 'scale-50 opacity-0'} transition-all duration-150 bg-white rounded border border-slate-100 py-4 px-8`}>
                <div className='flex justify-end'>
                    <button onClick={onClose} className='border-none outline-none focus:outline-none rounded-full p-2 hover:bg-slate-100'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="font-semibold text-center lg:whitespace-nowrap space-y-6">
                    <h1 className="text-[32px] text-[#4A3782]">กรมส่งเสริมการค้าระหว่างประเทศ</h1>
                    <div className="space-y-2">
                        <p className="text-[24px] text-[#C56E70]">จะดำเนินการปรับปรุงอุปกรณ์รักษาความปลอดภัยของเครือข่าย (Firewall)</p>
                        <p className="text-[24px] text-[#C56E70]">เพื่อเพิ่มประสิทธิภาพการใช้งานระบบเครือข่าย</p>
                    </div>
                    <div>
                        <p className="text-[24px] text-[#235B8D]">ในวันศุกร์ ที่ 14 มิถุนายน 2567</p>
                        <p className="text-[24px] text-[#C56E70]">เวลา 19:30 เป็นต้นไป</p>
                    </div>
                    <div className="h-[50px] w-[100px] bg-[#C56E70] mx-auto flex justify-center items-center">
                        <p className="text-[24px] text-white m-0">ถึง</p>
                    </div>
                    <div>
                        <p className="text-[24px] text-[#235B8D]">วันอาทิตย์ ที่ 16 มิถุนายน 2567</p>
                    </div>
                    <div className="space-y-2">
                        <p className="text-[16px] text-[#C56E70]">โดยส่งผลกระทบทำให้ผู้ประกอบการ</p>
                        <p className="text-[16px] text-[#C56E70]">และเจ้าหน้าที่ไม่สามารถเข้าถึงระบบสารสนเทศของกรมทุกระบบ</p>
                    </div>
                    <div className="pt-8">
                        <p className="text-[24px] text-[#C56E70]">ขออภัยในความไม่สะดวกมา ณ โอกาสนี้</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;